import { ContactUs } from "../components/Formularios"
import '../assets/css/Contacto.css'
function Contacto(){

    return(
        <div className="contacto-container">
            <div><p>Estamos aquí para ofrecerte nuestro apoyo y ayudarte en tus procesos psicológicos. En la mente de Lina, brindamos un espacio seguro y acogedor donde puedes explorar tus emociones y encontrar soluciones significativas. ¡Contáctanos hoy mismo y comienza tu viaje hacia el bienestar!</p></div>
            <div className="formulario-container"><ContactUs/></div>
        </div>
    )
}export {Contacto}