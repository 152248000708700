import '../assets/css/Footer.css'
import { SuscribeUs } from "./Formularios"

function Footer() {

    return (
        <div className="footer-container">
            <div className="footer-container-item">
                <h3>Suscribete</h3>
                <p>Recibe contenido exclusivo.</p>
                <SuscribeUs/>
            </div>
        </div>
    )
} export default Footer