import React from 'react'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import '../assets/css/firstaid.css'

function Firstaid() {

    return (
        <div className='first-aid-container'>
            <h1>Primeros auxilios psicológicos</h1>
            <div>
                <p>Somos conscientes de la importancia del cuidado psicológico y de los posibles 
                    riesgos que puede acarrear un tratamiento inapropiado. Por esta razón, queremos ofrecerte 
                    algunos pasos que puedes seguir en caso de enfrentar una crisis psicológica.
                </p>
            </div>
            <div>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography sx={{ color: 'var(--mandys-pink-300)' }}>Accordion 2</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography sx={{ color: 'var(--mandys-pink-300)' }}>Accordion 2</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ color: '#B69B8E' }}>
                        <Typography >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export { Firstaid }