import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navmenu } from './components/Navmenu';
import { Firstaid } from './pages/firstaid';
import { Contenido } from './pages/Contenido';
import { Videos, Podcast , Blog } from "./components/Multimedia";
import { Nosotros } from './pages/Nosotros';
import { Contacto } from './pages/Contacto';
import Footer from './components/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Navmenu />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/primeros-auxilios" element={<Firstaid/>} />
        <Route path="/contenido/videos" element={<Videos/>} />
        <Route path="/contenido/podcast" element={<Podcast/>} />
        <Route path="/contenido/blog" element={<Blog/>} />
        <Route path="/contenido" element={<Contenido/>} />
        <Route path="/Lina-Paola-Rivera-Sanchez" element={<Nosotros/>} />
        <Route path="/contenido/blog/:id" element={<Blog/>} />
        <Route path="/contacto" element={<Contacto/>} />
      </Routes>
      <Footer></Footer>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
