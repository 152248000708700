import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/Contenido.css";
import Blogs from "../assets/img/Blogs.png";
import Podcast from "../assets/img/Podcast.png";

function Contenido() {
  return (
    <div>
      <div className="contenido-buttons">
        <Link to="/contenido/videos" className="button-section">
          Video
        </Link>
        <Link to="/contenido/podcast" className="button-section">
          Podcast
        </Link>
        <Link to="/contenido/blog" className="button-section">
          Blog
        </Link>
      </div>

      <div className="selection-container">
        <h3>Selecciona el contenido adecuado para ti</h3>
        <p>
          Ofrecemos una variedad de contenidos para satisfacer tus necesidades,
          reconociendo tu individualidad.
        </p>
        <div className="selection-container-img">
          <Link to="videos">
            <div className="container-img-card">
              <div className="card-img">
                <img src={Blogs} alt="En-la-mente-de-lina-Videos"></img>
              </div>
              <div className="card-title">
                <h3>Video</h3>
              </div>
            </div>
          </Link>
          <Link to="podcast">
            <div className="container-img-card">
              <div className="card-img">
                <img src={Podcast} alt="En-la-mente-de-lina-podcast"></img>
              </div>
              <div className="card-title">
                <h3>Podcast</h3>
              </div>
            </div>
          </Link>
          <Link to="blog">
            <div className="container-img-card">
              <div className="card-img">
                <img src={Blogs} alt="En-la-mente-de-lina-blog"></img>
              </div>
              <div className="card-title">
                <h3>Blogs</h3>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div>
        <div>
          <Link to="/podcast"></Link>
        </div>
      </div>
    </div>
  );
}
export { Contenido };
