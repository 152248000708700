import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../assets/css/Navmenu.css';
import Logo from '../assets/img/Logo_en_la_mente_de_lina.svg'

function Navmenu(){

    const [activemenu,setActivemenu]=useState(true)
    const activation=()=>{
        const screenWidth = window.innerWidth;
        if(screenWidth <= 500){
            setActivemenu(!activemenu);
        }
        
    }


    return(
        <div className='Navmenu-container'>
            <div>
                <p><Link to='/'><img src={Logo} alt='Logo_en_la_mente_de_lina' className='Logo'></img></Link></p>
            </div>
            <div>
                <ul className={activemenu === true ? 'menu-inactive':'menu-active'}>
                    {/*<li><Link to='/' onClick={activation}>Inicio</Link></li>
                    <li><Link to='/primeros-auxilios' onClick={activation}>Primeros auxilios</Link></li>
                    <li><Link to='/contenido' onClick={activation}>Contenido</Link></li>
                    <li><Link to='/Lina-Paola-Rivera-Sanchez' onClick={activation}>Lina Paola Rivera Sanchez</Link></li>*/}
                    <li><Link to='/contacto' onClick={activation}>Contáctenos</Link></li>
                </ul>
            </div>
            <div className='icon-menu' onClick={activation}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}
export {Navmenu}