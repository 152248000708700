import "../assets/css/Nosotros.css";
import Lina from "../assets/img/Lina_Paola_Rivera_Sanchez.png";
import konrad from "../assets/img/Konrad_Lorenz.png";
import { IconBrandInstagram, IconBrandFacebook } from "@tabler/icons-react";
import ecopetrol from '../assets/img/Eco_logo_1_Color-3.png'
import cpc from '../assets/img/CPC.png'
function Nosotros() {
  return (
    <div className="Nosotros-container">
      <div className="Nosotros-presentacion">
        <div className="Nosotros-presentacion-texto">
          <h1>Lina Paola Rivera Sanchez</h1>
          <p>
            Como mujer y profesional en el campo de la psicología, mi
            pasión radica en guiar a las personas hacia un mayor entendimiento
            de sí mismas y del complejo mundo que las rodea. A través de una
            combinación de empatía, comprensión y conocimientos especializados,
            me esfuerzo por ofrecer un espacio seguro donde mis pacientes puedan
            explorar sus emociones, pensamientos y comportamientos.
            <br></br>
            <br></br>
            Creo firmemente en el poder de la autoconciencia y la autorreflexión
            como herramientas fundamentales para el crecimiento personal y el
            bienestar emocional. Mi enfoque terapéutico se centra en ayudar a
            mis pacientes a comprender los procesos cognitivos que subyacen a
            sus experiencias, así como en fomentar la capacidad de reconocer y
            gestionar eficazmente sus emociones.
            <br></br>
            <br></br>
            Como psicóloga, estoy comprometida a proporcionar un tratamiento
            individualizado y orientado a resultados, adaptado a las necesidades
            únicas de cada persona que busca mi ayuda. Creo en la importancia de
            una relación terapéutica basada en la confianza, el respeto y la
            colaboración, donde mis pacientes se sientan capacitados para
            explorar sus desafíos y trabajar hacia el cambio positivo.
            <br></br>
            <br></br>
            Mi objetivo final es capacitar a mis pacientes para que se
            conviertan en los expertos de su propia vida, dotándolos de las
            herramientas y estrategias necesarias para enfrentar los desafíos
            con confianza y resiliencia. Como mujer y psicóloga
            comprometida, me siento honrada de acompañar a otros en su viaje
            hacia la autoaceptación, el crecimiento personal y el bienestar
            emocional.
          </p>
          <div className="Nosotros-social">
            <a href="/https://www.instagram.com/enlamentedelina/">
              <IconBrandInstagram size={40} color="var(--mandys-pink-300)" />
            </a>
            <a href="/https://www.instagram.com/enlamentedelina/">
              <IconBrandFacebook size={40} color="var(--mandys-pink-300)" />
            </a>
          </div>
        </div>
        <div>
          <img src={Lina} alt="Lina_paola_rivera_sanchez"></img>
        </div>
      </div>
      <div className="conocimiento">
        <div>
          <h2>Psicología</h2>
          <p>Psicóloga clínica, egresada de la universidad Konrad Lorenz, cuenta con una profundización en primeros auxilios psicológicos, su terapia clínica es guiada por un enfoque cognitivo- conductual, cuenta con su consultorio virtual NUETRA-MENTE, en el cual trabaja de manera individual, ha trabajado con el consultorio clínico CPC de la universidad Konrad Lorenz y actualmente está vinculada con la IPS RIIE, desempeñándose como psicóloga clínica. </p>
        </div>
        <img src={konrad} alt="Konrad_lorenz"></img>
      </div>
      <div className="experiencia-container">
        <h2>Experiencia</h2>
        <div className="experiencia-items">
        <a href="https://cpc.konradlorenz.edu.co/">
            <img
              src={cpc}
              alt="CPC_Konrad_Lorence"
            ></img>
          </a>
          <a href="https://www.riie.com.co/">
            <img
              src="https://www.riie.com.co/wp-content/uploads/2020/01/Riie-imagen-Vectorizada-peque%C3%B1a-300x92.png"
              alt="Riie"
            ></img>
          </a>
          <a href="https://www.ecopetrol.com.co/wps/portal/">
            <img
              src={ecopetrol}
              alt="Ecopetrol"
            ></img>
          </a>
          <a href="https://www.nordvitalips.com/">
            <img
              src="https://www.nordvitalips.com/wp-content/uploads/2018/09/Logo.png"
              alt="Nordvital_IPS"
            ></img>
          </a>
          <a href="https://corporativo.compensar.com/">
            <img
              src="https://corporativo.compensar.com/PublishingImages/logo_compensar.png"
              alt="Compensar"
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
}
export { Nosotros };
