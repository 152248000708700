import "./App.css";
import Lina from "../src/assets/img/1625523639770.png";
import { Link } from "react-router-dom";
import familia from "./assets/img/Familia_en_la_mente_de_lina.png";
import pareja from "./assets/img/Pareja_en_la_mente_de_lina.png";
import Infantil from "./assets/img/Infantil_en_la_mente_de_lina.png";
import individual from "./assets/img/Individual_en_la_mente_de_lina.png";

function App() {
  return (
    <div className="App">
      <div className="intro-section">
        <div className="intro-section-items">
          <h1>
            Más allá de lo <span>conciente</span>
          </h1>
          <p>
            Descubre nuestra Guía Psicológica para entender y superar los
            procesos de la mente. Encuentra consejos prácticos y estrategias
            probadas respaldadas por años de experiencia en psicología.
          </p>
          {/*<Link to="/contenido">Contenido</Link>*/}
        </div>
        <div>
          <img
            className="imags"
            src={Lina}
            alt="Psicologa - Lina Paola Rivera Sanchez"
          ></img>
        </div>
      </div>
      {/*
      <div className="first-aid">
        <h3>Primeros auxilios psicológicos</h3>
        <p>Que debo de hacer ante una crisis.</p>
        <Link to="/primeros-auxilios">Paso a paso</Link>
      </div>
        */}
      <div className="therapies-container">
        <h3>Terapias</h3>
        <div className="therapies-cards">
          <div>
            <img src={familia} alt="terapia_familiar"></img>
            <h3>Familiar</h3>
            <p>
              Descubre el poder de la armonía familiar con nuestro servicio de
              psicología especializado.
            </p>
           
          </div>
          <div>
            <img src={pareja} alt="terapia_parejas"></img>
            <h3>Parejas</h3>
            <p>
              Explora nuevas formas de conexión y entendimiento, trabajemos
              juntos para superar conflictos, fortalecer la comunicación y
              revitalizar la intimidad en tu relación.
            </p>
        
          </div>
          <div>
            <img src={Infantil} alt="terapia_infantil"></img>
            <h3>Infantil</h3>
            <p>
              Apoyamos el desarrollo emocional y el bienestar de tu hijo,
              Nuestro propósito es ayudar a los niños a superar desafíos
              emocionales, mejorar la autoestima y fortalecer las habilidades
              sociales.
            </p>
          </div>
          <div>
            <img src={individual} alt="terapia_individual"></img>
            <h3>Personal</h3>
            <p>
              Trabaja en un ambiente de apoyo y confidencialidad para explorar
              tus pensamientos, emociones y comportamientos.
            </p>
          </div>
        </div>
      </div>
      <div className="first-aid">
        <h3>Solicita tu consulta personalizada</h3>
        <p>
          Cuenta con un acompañamiento profesional, supera cada uno de los
          conflictos de tu mente.
        </p>
        <Link to="/contacto">Agenda</Link>
      </div>
    </div>
  );
}

export default App;
