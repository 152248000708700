import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from '@mui/material/Alert';

import '../assets/css/Formularios.css'
import { TextField , createTheme,ThemeProvider} from "@mui/material";
import swal from 'sweetalert';


export const ContactUs = () => {
  const form = useRef();

  const [alert, setAlert] = useState(null)
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_2amzdib", "template_6hb9ci8", form.current, {
        publicKey: "-EorKz2_2oOYBHtHh",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setAlert(<Alert severity="success">Gracias por ponerte en contacto con nosotros</Alert>)
        },
        (error) => {
          console.log("FAILED...", error.text);
          setAlert(<Alert variant="outlined" severity="error">
            Lo siento el mensaje no se ha podido enviar, verifica tu conexion a internet he intentalo de nuevo.
          </Alert>)
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      {alert}
      <label>Nombre</label>
      <input type="text" name="to_name" />
      <br></br>
      <label>Apellido</label>
      <input type="text" name="to_lastname" />
      <br></br>
      <label>Ciudad</label>
      <input type="text" name="to_city" />
      <br></br>
      <label>Email</label>
      <input type="email" name="from_mail" />
      <br></br>

      <label for="pet-select">Motivo de consulta</label>

      <select name="consulta">
        <option value="Otro">Seleccione una opción</option>
        <option value="Personal">Personal</option>
        <option value="Familiar">Familiar</option>
        <option value="Pareja">Pareja</option>
        <option value="Infantil">Infantil</option>
        <option value="Otro">Otro</option>
      </select>
      <br></br>
      <br></br>
      <label>Message:</label>
      <br></br>
      <textarea name="message" />
      <br></br>
      <button type="submit"  className="buttons">Enviar</button>
    </form>
  );
};



export const SuscribeUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_2amzdib", "template_m3z1qb6", form.current, {
        publicKey: "-EorKz2_2oOYBHtHh",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          swal("Gracias por registrarte", "Ahora haces parte de esta gran comunidad.", "success");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: '#facab9', // Color rosado para el borde
      },
    },
  });
  return (
    <form ref={form} onSubmit={sendEmail}>
      <ThemeProvider theme={theme}>


      <TextField
          label="Tu correo"
          defaultValue=""
          name="from_mail"  
          variant="outlined"
          InputProps={{
            style: {
              color: '#B69B8E', // Color café para el texto
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'primary.main', // Cambia el borde a rosado cuando está enfocado
              },
            },
          }}   
        />
              </ThemeProvider>
      <button type="submit" className="send-button">Enviar</button>
    </form>
  );
};
