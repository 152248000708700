import youtube from "../assets/img/youtube_icon.svg";
import { Link, useParams } from "react-router-dom";
import Spotify from "../assets/img/spotify_icon.svg";
import "../assets/css/Multimedia.css";
import { Blogs } from "../Const/Const";
import { useState, useEffect } from "react";

function Videos() {
  console.log(Blogs);

  return (
    <div className="multimedia-container">
      <div className="contenido-buttons">
        <Link to="/contenido/videos" className="button-section">
          Video
        </Link>
        <Link to="/contenido/podcast" className="button-section">
          Podcast
        </Link>
        <Link to="/contenido/blog" className="button-section">
          Blog
        </Link>
      </div>
      <div className="title-description-container">
        <h1>Videos</h1>
        <p>
          ¡Bienvenido al mundo visual de la exploración emocional! En estos
          vídeos, te invito a embarcarte en un viaje de autodescubrimiento
          emocional, donde podrás explorar y comprender tus sentimientos desde
          una perspectiva profesional. Acompáñame mientras navegamos por las
          complejidades de nuestras emociones y encontramos maneras saludables
          de procesarlas.
        </p>
      </div>
      <div className="link-multimedia">
        <h2>Puedes vernos a traves de</h2>
        <div className="img-icons">
          <a href="https://www.youtube.com/@enlamentedelina">
            <img src={youtube} alt="escuchanos_por_youtube"></img>
          </a>
          <a href="https://www.youtube.com/@enlamentedelina">
            <img src={Spotify} alt="escuchanos_por_spotify"></img>
          </a>
        </div>
      </div>
    </div>
  );
}

function Podcast() {
  return (
    <div className="multimedia-container">
      <div className="contenido-buttons">
        <Link to="/contenido/videos" className="button-section">
          Video
        </Link>
        <Link to="/contenido/podcast" className="button-section">
          Podcast
        </Link>
        <Link to="/contenido/blog" className="button-section">
          Blog
        </Link>
      </div>
      <div className="title-description-container">
        <h1>Podcast</h1>
        <p>
          Sumérgete en un espacio donde tus emociones tienen voz. Te invito a un
          viaje para explorar y comprender tus sentimientos desde una
          perspectiva psicológica profesional. Estoy aquí para acompañarte en
          este proceso de autoconocimiento y asimilación emocional.
        </p>
      </div>
      <div className="link-multimedia">
        <h2>Puedes escucharnos a traves de</h2>
        <div>
          <a href="https://www.youtube.com/@enlamentedelina">
            <img src={Spotify} alt="escuchanos_por_spotify"></img>
          </a>
        </div>
      </div>
    </div>
  );
}

function Blog() {
  const blog = useParams();

  const [ventana, setVentana] = useState(null);

  useEffect(() => {
    if (blog.id !== undefined) {
      const blogEncontrado = Blogs.find((objeto) => objeto.link === blog.id); // Asumiendo que el id es un número
      if (blogEncontrado) {
        setVentana(blogEncontrado);
      } else {
        setVentana(null); // Reiniciar ventana si no se encuentra ningún blog con el id proporcionado
      }
    }
  }, [blog.id]);

  return (
    <div className="multimedia-container">
      <div className="contenido-buttons">
        <Link to="/contenido/videos" className="button-section">
          Video
        </Link>
        <Link to="/contenido/podcast" className="button-section">
          Podcast
        </Link>
        <Link to="/contenido/blog" className="button-section">
          Blog
        </Link>
      </div>
      <div className="title-description-container">
        <h1>Blog</h1>
        <p>
          Sumérgete en un espacio de reflexión y crecimiento personal. En
          nuestro blog, exploramos diversos temas relacionados con el bienestar
          emocional y el desarrollo personal. Desde consejos prácticos hasta
          reflexiones profundas, estamos aquí para inspirarte y apoyarte en tu
          camino hacia una vida más plena y satisfactoria.
        </p>
      </div>
      <div className="item-blog">
        {Blogs.map((item) => (
          <Link to={item.link}>
            <div>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </Link>
        ))}
        <div
          className={
            blog.id === undefined ? "box-blog-text" : "box-blog-text-active"
          }
        >
          <div className="close-x">
            <Link to="/contenido/blog">X</Link>
          </div>
          <div className="section-blog">
            {ventana !== null ? (
              <>
                <h1>{ventana.title}</h1>
                <p dangerouslySetInnerHTML={{ __html: ventana.text }}></p>
              </>
            ) : (
              <p>sin datos</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export { Videos, Podcast, Blog };
